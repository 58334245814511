import initialState from './initialState';
import { LoadingStatusEnum } from '../../../constants/enums';

// --------------------------------------------------------------------

export const CreateUserReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: [...state.list, data],
      unbindEmployees: state.unbindEmployees.filter(({ _id }) => _id !== data.employee?._id),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const CreateUserByAdminReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: [...state.list, data],
      // unbindEmployees: state.unbindEmployees.filter(({ _id }) => _id !== data.employee?._id),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetUsersReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data, withoutFactory } = action.payload;

    return {
      ...state,
      list: withoutFactory ? data?.usersWithoutFactory : data?.list,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const UpdateUserReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    const oldUserData = state.list.find((item) => item._id === data._id);
    const oldEmployee = oldUserData?.employee;

    return {
      ...state,
      list: state.list.map((item) => {
        if (item._id === data._id) return data;

        return item;
      }),
      unbindEmployees: [...state.unbindEmployees, ...(oldEmployee ? [oldEmployee] : [])].filter(
        ({ _id }) => _id !== data.employee?._id
      ),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const UpdateUserConveyorReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state) => ({
    ...state,
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const changePasswordReducer = {
  fulfilled: (state, action) => {
    const { userId, newPassword } = action.payload;

    return {
      ...state,
      list: state.list.map((user) => {
        if (user._id === userId) {
          return {
            ...user,
            password: newPassword,
          };
        }
        return user;
      }),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetEmployeesReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      unbindEmployees: data?.list || [],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetConveyorStepsReducer = {
  fulfilled: (state, action) => {
    const { conveyorTemplates, conveyorStepTemplates } = action.payload;

    return {
      ...state,
      conveyorTemplates,
      conveyorStepTemplates,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetFeaturePermissionsReducer = {
  fulfilled: (state, action) => ({
    ...state,
    featurePermissions: action.payload.data || [],
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetPagePermissionsTemplatesReducer = {
  pending: (state) => ({
    ...state,
    pagePermissionsTemplates: {
      ...state.pagePermissionsTemplates,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    pagePermissionsTemplates: {
      list: action.payload.data.list || [],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    pagePermissionsTemplates: {
      ...state.pagePermissionsTemplates,
      loading: 'failed',
    },
  }),
};

export const GetConveyorPermissionsTemplatesReducer = {
  pending: (state) => ({
    ...state,
    conveyorPermissionsTemplates: {
      ...state.conveyorPermissionsTemplates,
      loading: 'pending',
    },
  }),
  fulfilled: (state, action) => ({
    ...state,
    conveyorPermissionsTemplates: {
      list: action.payload.data.list || [],
      loading: 'succeeded',
    },
  }),
  rejected: (state) => ({
    ...state,
    conveyorPermissionsTemplates: {
      ...state.conveyorPermissionsTemplates,
      loading: 'failed',
    },
  }),
};

export const ChangeUserInactiveStatusReducer = {
  pending: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Pending,
  }),
  fulfilled: (state, action) => {
    const { list } = state;
    const user = action.payload.data;
    const updatedList = list.map((item) => {
      if (item._id === user._id) return user;
      return item;
    });
    return {
      ...state,
      list: updatedList,
      loading: LoadingStatusEnum.Succeeded,
    };
  },
  rejected: (state) => ({
    ...state,
    loading: LoadingStatusEnum.Failed,
  }),
};

export const ClearPagePermissionsTemplatesReducer = (state) => ({
  ...state,
  pagePermissionsTemplates: initialState.pagePermissionsTemplates,
});

export const ClearConveyorPermissionsTemplatesReducer = (state) => ({
  ...state,
  conveyorPermissionsTemplates: initialState.conveyorPermissionsTemplates,
});

export const ResetUsersStateReducer = () => initialState;
