import { createSlice } from '@reduxjs/toolkit';
import { addExtraReducer } from '../../utils/addExtraReducer';
import initialState from './initialState';
import { GetConfigsReducer, GetConveyorTransactionsReducer, ClearListReducer } from './reducers';
import { GetConfigs, GetConveyorTransactions, GetCigarConveyorTransactions } from './thunks';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'conveyorTransactions',
  initialState,
  reducers: {
    ClearList: ClearListReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetConveyorTransactions, GetConveyorTransactionsReducer);
    addExtraReducer(builder, GetCigarConveyorTransactions, GetConveyorTransactionsReducer);
    addExtraReducer(builder, GetConfigs, GetConfigsReducer);
  },
});

export default slice.reducer;
