import { Card, Table, TableBody, TableContainer, TableRow, Typography } from '@mui/material';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import QRTooltip from '../../../components/tooltips/QRTooltip';
import getLocalizedText from '../../../utils/getLocalizedText';
import { applySortFilter, getComparator } from '../../../utils/tableUtils';
import { TableCell, TableHead, TableToolbar } from '../table';

// ----------------------------------------------------------------------

const getTableHead = (t) => [
  { id: 'conveyorTemplate.name', label: t('label.conveyorTemplate'), alignRight: false },
  { id: 'stepTemplate.name', label: t('label.step'), alignRight: false },
  { id: 'qrCode', label: t('label.qrCode'), alignRight: false },
  { id: 'itemName', label: t('label.item'), alignRight: false },
  { id: 'type', label: t('label.type'), alignRight: false },
  { id: 'originalItemQuantity', label: t('label.originalQuantity'), alignRight: true },
  { id: 'itemQuantity', label: t('label.quantity'), alignRight: true },
  { id: 'difference', label: t('label.difference'), alignRight: true },
  { id: 'tobaccoContainer', label: t('label.tobaccoContainer'), alignRight: false },
  { id: 'tobacco', label: t('label.tobacco'), alignRight: false },
  { id: 'user', label: t('label.user'), alignRight: false },
  { id: 'updatedAt', label: t('table.updated'), alignRight: false },
  { id: 'createdAt', label: t('table.created'), alignRight: false },
];

const getFilteredItem = (item, t, i18n) => {
  const conveyorTemplateName = getLocalizedText({
    language: i18n.language,
    en: item.conveyorTemplate?.name || item.conveyorTemplateOriginalName,
    es: item.conveyorTemplate?.nameEs,
  });

  const stepTemplateName = getLocalizedText({
    language: i18n.language,
    en: item.stepTemplate?.name || item.stepTemplateOriginalName,
    es: item.stepTemplate?.nameEs,
  });

  return {
    conveyorTemplateName,
    stepTemplateName,
    qrId: item.qrId,
    itemName: item.itemName,
    originalItemQuantity: item.originalItemQuantity,
    itemQuantity: item.itemQuantity,
    createdBy: item.createdBy?.fullName,
    tobaccoContainer: item.tobaccoContainer,
    tobacco: item.tobacco,
    updatedAt: t('date.shortWithTime', { date: parseISO(item.updatedAt) }),
    createdAt: t('date.shortWithTime', { date: parseISO(item.createdAt) }),
  };
};

// ----------------------------------------------------------------------

export default function ConveyorStepRecordTransactionsTable({ list, loading }) {
  const { t, i18n } = useTranslation();

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('createdAt');

  const [filterName, setFilterName] = useState('');

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (value) => {
    setFilterName(value);
  };

  const tableHead = getTableHead(t);

  const filteredItems = useMemo(
    () => applySortFilter(list, getComparator(order, orderBy), filterName, (item) => getFilteredItem(item, t, i18n)),
    [filterName, i18n, list, order, orderBy, t]
  );

  const isItemNotFound = filteredItems.length === 0;

  return (
    <Card>
      <TableToolbar filterName={filterName} onFilterName={handleFilterByName} submitOnEnter />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredItems?.map((row) => {
                const {
                  _id,
                  conveyorTemplateOriginalName,
                  conveyorTemplate,
                  qrId,
                  stepTemplateOriginalName,
                  stepTemplate,
                  itemName,
                  originalItemQuantity,
                  itemQuantity,
                  tobaccoContainer,
                  tobacco,
                  createdBy,
                  updatedAt,
                  createdAt,
                  type,
                } = row;
                const difference = itemQuantity - originalItemQuantity;
                const conveyorTemplateName = getLocalizedText({
                  language: i18n.language,
                  en: conveyorTemplate?.name || conveyorTemplateOriginalName,
                  es: conveyorTemplate?.nameEs,
                });

                const stepTemplateName = getLocalizedText({
                  language: i18n.language,
                  en: stepTemplate?.name || stepTemplateOriginalName,
                  es: stepTemplate?.nameEs,
                });

                return (
                  <TableRow hover key={_id}>
                    <TableCell align="left" isLoading={loading}>
                      {conveyorTemplateName}
                    </TableCell>
                    <TableCell align="left" isLoading={loading}>
                      {stepTemplateName}
                    </TableCell>
                    <TableCell align="left" isLoading={loading}>
                      <QRTooltip qr={qrId} />
                      loading
                    </TableCell>
                    <TableCell align="left" isLoading={loading}>
                      {itemName}
                    </TableCell>
                    <TableCell align="left" isLoading={loading}>
                      {type}
                    </TableCell>
                    <TableCell align="right" isLoading={loading}>
                      {originalItemQuantity?.toFixed(2)}
                    </TableCell>
                    <TableCell align="right" isLoading={loading}>
                      {itemQuantity?.toFixed(2)}
                    </TableCell>
                    <TableCell align="right" isLoading={loading}>
                      <Typography color={difference < 0 ? 'error.main' : 'success.main'}>
                        {difference?.toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" isLoading={loading}>
                      {tobaccoContainer}
                    </TableCell>
                    <TableCell align="left" isLoading={loading}>
                      {tobacco}
                    </TableCell>
                    <TableCell align="left" isLoading={loading}>
                      {createdBy?.fullName}
                    </TableCell>
                    <TableCell align="left" isLoading={loading}>
                      {updatedAt ? t('date.shortWithTime', { date: parseISO(updatedAt) }) : '-'}
                    </TableCell>
                    <TableCell align="left" isLoading={loading}>
                      {createdAt ? t('date.shortWithTime', { date: parseISO(createdAt) }) : '-'}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>

            {isItemNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={tableHead.length} sx={{ py: 3 }}>
                    <SearchNotFound isLoading={loading} searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

ConveyorStepRecordTransactionsTable.propTypes = {
  list: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
