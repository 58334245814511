import { LoadingStatusEnum } from '../../../constants/enums';

// ----------------------------------------------------------------

export const selectRoomsList = (state) => state.rooms.list;
export const selectRoomsLoading = (state) => state.rooms.loading;
export const selectRoomsIsLoading = (state) => state.rooms.loading === LoadingStatusEnum.Pending;

export default {
  rooms: selectRoomsList,
  loading: selectRoomsLoading,
  isLoading: selectRoomsIsLoading,
};
