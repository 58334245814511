import { format, formatDistanceToNow, formatDuration, intervalToDuration, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ToastEmitter from '../components/toaster/ToastEmmited';

// ----------------------------------------------------------------------

export function getDatesInRange(startDate, endDate) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  if (!startDate || !endDate) {
    return [];
  }
  const currentDate = dayjs(startDate).tz('UTC').toDate();
  const end = dayjs(endDate).tz('UTC').toDate();

  const dates = [];

  while (currentDate <= end) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

export function timeStringToDate(timeString) {
  const timeArray = timeString.split(':');

  if (timeArray.length < 2) {
    ToastEmitter.notify(`Time string invalid format:`, 'error');
    return null;
  }

  return new Date(null, null, null, ...timeArray);
}

export function dateToTimeString(date) {
  if (date.indexOf('GMT') > 0) {
    date = date.split('GMT').shift();
  }
  const time = format(new Date(date), 'HH:mm');
  return time;
}

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function getDateOfISOWeek(week, year) {
  // Get date for 1 Jan in given year
  const d = new Date(year, 0, 1);
  const dow = d.getDay();

  // Shift to start of ISO week 1
  d.setDate((dow <= 4 ? 2 : 9) - d.getDay());

  // Add required number of weeks
  d.setDate(d.getDate() + (week - 1) * 7);

  return d;
}

function getFirstDayOfWeek(week, year, startDay) {
  const date = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7));
  const day = date.getUTCDay();
  const firstDayOfWeek = date.getUTCDate() - day + (day <= startDay ? startDay - day : 7 + startDay - day);
  return new Date(date.setUTCDate(firstDayOfWeek));
}

export function getDayOfISOWeek(day, week, year) {
  const d = getDateOfISOWeek(week, year);
  d.setDate(d.getDate() + (Number(day) || 7) - 1);
  return d;
}

export function getDayOfWeek(day, week, year, weekday) {
  const firstDay = getFirstDayOfWeek(week, year, weekday);
  firstDay.setDate(firstDay.getDate() + (Number(day) || 7) - 1);
  return firstDay;
}

export function createWeekdaysList(firstDay) {
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const startIndex = weekdays.indexOf(firstDay);
  const weekdaysList = [...weekdays.slice(startIndex), ...weekdays.slice(0, startIndex)];
  return weekdaysList.reduce((obj, day) => {
    obj[day] = day;
    return obj;
  }, {});
}

function getWeekdays(startDate, endDate) {
  const weekdays = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    weekdays.push({
      currentDate,
    });
    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
  }
  return weekdays;
}

export function getWeekdayOfYearAndMonth(year, month, day) {
  const date = new Date(year, month, day);
  const options = { weekday: 'long' };
  const weekday = date.toLocaleString('en-US', options);
  return weekday;
}

export function getFirstWeekdayOfYearAndMonth(year, month, weekday) {
  let day = 1;
  const date = new Date(year, month, 1);
  while (getWeekdayOfYearAndMonth(year, month, day) !== weekday) {
    day += 1;
  }
  date.setDate(date.getDate() + day - 1);
  return date;
}

export function getWeekNumber(date, startWeekday) {
  const firstDayOfFirstWeek = getFirstWeekdayOfYearAndMonth(date.getUTCFullYear(), 0, startWeekday);
  const diff = date.getTime() - firstDayOfFirstWeek.getTime();
  const days = Math.ceil(diff / (1000 * 3600 * 24));
  return days / 7 + 1;
}

export function getWeeksInMonth(month, year, startWeekday) {
  const weeks = [];
  const firstDayOfFirstWeek = getFirstWeekdayOfYearAndMonth(year, month, startWeekday);
  let currentWeekStart = firstDayOfFirstWeek;
  let currentWeekEnd = new Date(
    currentWeekStart.getFullYear(),
    currentWeekStart.getMonth(),
    currentWeekStart.getDate() + 6
  );
  let weekCounter = getWeekNumber(currentWeekStart, startWeekday);
  while (currentWeekStart.getMonth() === month) {
    weeks.push({
      week: weekCounter,
      year,
      start: currentWeekStart,
      end: currentWeekEnd,
      weekdays: getWeekdays(currentWeekStart, currentWeekEnd),
    });
    currentWeekStart = new Date(
      currentWeekStart.getFullYear(),
      currentWeekStart.getMonth(),
      currentWeekStart.getDate() + 7
    );
    currentWeekEnd = new Date(currentWeekEnd.getFullYear(), currentWeekEnd.getMonth(), currentWeekEnd.getDate() + 7);
    weekCounter += 1;
  }
  return weeks.sort((a, b) => (a.week < b.week ? 1 : -1));
}

export function getTimeDifferenceWithDefaultEnd(start, end) {
  if (!start) {
    return 'N/A';
  }
  const startDate = parseISO(start);
  const endDate = end ? parseISO(end) : new Date();
  if (!startDate) {
    return 'N/A';
  }
  const duration = intervalToDuration({
    start: startDate,
    end: endDate,
  });

  return duration;
}

export function getTimeDifferenceWithDefaultEndFormatted(start, end, locale) {
  const duration = getTimeDifferenceWithDefaultEnd(start, end);
  const totalSeconds =
    duration.years * 365 * 24 * 60 * 60 +
    duration.months * 30 * 24 * 60 * 60 +
    duration.hours * 3600 +
    duration.minutes * 60 +
    duration.seconds;

  const formatted =
    totalSeconds <= 59
      ? `${duration.seconds} seconds` // Show seconds if total duration is 59 seconds or less
      : formatDuration(duration, {
          delimiter: ' ',
          locale,
          format: ['years', 'months', 'days', 'hours', 'minutes'], // Include years and months, exclude seconds
        });

  return formatted;
}

export function formatMinutes(minutes) {
  if (minutes === null || minutes === undefined) return null;
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours}h ${mins}m`;
}
