const initialState = {
  data: {
    list: [],
    loading: 'idle',
  },
  corrections: null,
  configs: [],
  selectedConveyorHeader: null,
  loading: 'idle',
};

export default initialState;
