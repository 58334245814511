import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const conveyorPermissionsTemplateApi = {
  // GET
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/conveyor-permissions-template/v1/`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // PUT
  updateUserConveyorPermissions: async (userId, data) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/conveyor-permissions-template/v1/update-conveyor-permissions/${userId}`, {
        data,
      });

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default conveyorPermissionsTemplateApi;
