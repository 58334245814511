import { Card, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import useAccess from '../../../hooks/useAccess';
import { dateLocales } from '../../../locales/i18n';
import selectors from '../../../redux/reducers/conveyorTransactions/selectors';
import { getTimeDifferenceWithDefaultEnd, getTimeDifferenceWithDefaultEndFormatted } from '../../../utils/formatTime';
import { getTobaccoLabel } from '../../../utils/modelLabels';
import { applySortFilter, getComparator } from '../../../utils/tableUtils';
import { ExtraSmallTableCell as TableCell, TableHead, TableToolbar } from '../table';
import ConveyorTransactionsRow from './ConveyorTransactionsRow';

const SIZE = 'xs';

// ----------------------------------------------------------------------

const getTableHead = (t) => [
  { id: 'tobacco', label: t('label.tobacco'), alignRight: false },
  { id: 'transactions[0].qrId', label: t('table.qr'), alignRight: false },
  { id: 'table', alignRight: false },
];

// ----------------------------------------------------------------------

const getFilteredItem = (item) => {
  const { transactions } = item;
  const firstTransaction = transactions[0];
  const tobaccoCode = firstTransaction?.tobacco.code;

  return {
    tobacco: getTobaccoLabel(firstTransaction?.tobacco),
    flatCode: tobaccoCode?.replace(/_/g, ''),
    qrId: firstTransaction?.qrId,
    ...item.transactions?.reduce((acc, transaction) => {
      const {
        _id,
        type,
        quantity,
        createdBy,
        currentQuantity,
        costPerUnit,
        costPerUnitDifferenceInPercent,
        costPerUnitDifferenceInPercentDisplay,
        updatedAt,
        conveyorStep,
        difference,
        differenceInPercent,
        differenceInPercentDisplay,
        factoryDepartment,
        durationFormatted,
      } = transaction;

      return {
        ...acc,

        [`transaction_${_id}_type`]: type,
        [`transaction_${_id}_quantity`]: quantity,
        [`transaction_${_id}_createdBy`]: createdBy,
        [`transaction_${_id}_currentQuantity`]: currentQuantity,
        [`transaction_${_id}_costPerUnitDifferenceInPercent`]: costPerUnitDifferenceInPercent,
        [`transaction_${_id}_costPerUnitDifferenceInPercentDisplay`]: costPerUnitDifferenceInPercentDisplay,
        [`transaction_${_id}_costPerUnit`]: costPerUnit,
        [`transaction_${_id}_conveyorStep`]: conveyorStep?.name,
        [`transaction_${_id}_updatedAt`]: updatedAt,
        [`transaction_${_id}_difference`]: difference,
        [`transaction_${_id}_differenceInPercent`]: differenceInPercent,
        [`transaction_${_id}_differenceInPercentDisplay`]: differenceInPercentDisplay,
        [`transaction_${_id}_factoryDepartment`]: factoryDepartment?.name,
        [`transaction_${_id}_durationFormatted`]: durationFormatted,
      };
    }, {}),
  };
};

export default function ConveyorTobaccoTransactionsTable({
  list = [],
  corrections = [],
  loading,
  handleOpenCorrectionModal,
  handleOpenCorrectionHistoryInfo,
}) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language || window.localStorage.i18nextLng || 'en';
  const locale = dateLocales[lang];
  const configs = useSelector(selectors.configs);
  const pageConfig = configs.map((config) => ({
    conveyorStepId: config.conveyorStepId,
    name: config.name,
    nameEs: config.nameEs,
    rules: config.rules,
    primaryTransactionType: config.primaryTransactionType,
  }));

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [filterName, setFilterName] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (value) => {
    setFilterLoading(true);
    setFilterName(value);
  };

  const computedFilteredList = useMemo(() => {
    if (!list) return [];

    const listWithUpdatedTransactions = list.map((item, index) => {
      const updatedItem = {
        ...item,
        tobacco: getTobaccoLabel(item.transactions[0].tobacco),
        transactions: [...item.transactions],
        indx: index,
      };

      pageConfig.forEach((config) => {
        if (!config?.rules?.stepIsRequired) return;

        if (config?.rules?.requiredIfPrevStepExistId) {
          const prevTransactionExists = item.transactions.some(
            (transaction) => transaction.conveyorStep._id === config.rules.requiredIfPrevStepExistId
          );
          if (!prevTransactionExists) return;
        }

        const transactionExists = updatedItem.transactions.some(
          (transaction) => transaction.conveyorStep._id === config.conveyorStepId
        );

        if (!transactionExists) {
          updatedItem.transactions.push({
            _id: uuidv4(),
            conveyorStep: { _id: config.conveyorStepId, name: config.name, nameEs: config.nameEs },
            type: config.primaryTransactionType,
            costPerUnit: null,
            currentQuantity: null,
            createdBy: null,
            updatedAt: new Date().toISOString(),
            createdAt: new Date().toISOString(),
            difference: null,
            differenceInPercent: null,
            factoryDepartment: null,
          });
        }
      });

      return updatedItem;
    });

    const upgradedList = listWithUpdatedTransactions.map((item) => ({
      ...item,
      transactions: item.transactions.map((transaction, index) => {
        const currentQuantity = transaction.quantity || 0;
        const lastTransaction = item.transactions[index - 1];
        const previousQuantity = index > 0 ? lastTransaction.quantity || 0 : 0;

        const difference = index !== 0 ? (currentQuantity - previousQuantity).toFixed(2) : null;

        const differenceInPercent = (() => {
          if (index === 0) return null;
          if (previousQuantity === 0) return 0;
          return (difference / previousQuantity) * 100;
        })();

        const calculateOperationDuration = (previousDate, currentDate) => {
          const duration = getTimeDifferenceWithDefaultEnd(previousDate, currentDate);
          const durationTotalMinutes = duration?.minutes + duration?.hours * 60 + duration?.days * 24 * 60;
          const durationTotalSeconds = duration?.seconds + durationTotalMinutes * 60;

          return durationTotalSeconds;
        };

        const operationDuration =
          index === 0 ? 0 : calculateOperationDuration(lastTransaction.createdAt, transaction.createdAt);

        const durationFormatted =
          index === 0
            ? null
            : getTimeDifferenceWithDefaultEndFormatted(lastTransaction.createdAt, transaction.createdAt, locale);

        const costPerUnitDifferenceInPercent = (() => {
          if (index === 0) return null;
          if (transaction.costPerUnit === null || transaction.costPerUnit === undefined) return null;

          const previousCostPerUnit = lastTransaction.costPerUnit;
          if (previousCostPerUnit === 0) return 0;
          return ((transaction.costPerUnit - previousCostPerUnit) / previousCostPerUnit) * 100;
        })();

        const differenceInPercentDisplay = differenceInPercent ? `${differenceInPercent.toFixed(2)} %` : '0 %';
        const costPerUnitDifferenceInPercentDisplay = costPerUnitDifferenceInPercent
          ? `${costPerUnitDifferenceInPercent.toFixed(2)} %`
          : '0 %';

        return {
          ...transaction,
          createdBy: transaction.createdBy === null ? 'N/A' : transaction.createdBy?.fullName,
          updatedAt: t('date.shortWithTime', { date: parseISO(transaction.updatedAt) }),
          createdAt: t('date.shortWithTime', { date: parseISO(transaction.createdAt) }),
          currentQuantity: currentQuantity?.toFixed(2),
          previousQuantity: previousQuantity?.toFixed(2),
          difference: transaction.difference === null ? 'N/A' : difference,
          differenceInPercent,
          differenceInPercentDisplay: transaction.differenceInPercent === null ? 'N/A' : differenceInPercentDisplay,
          operationDuration,
          durationFormatted,
          costPerUnit: transaction.costPerUnit === null ? 'N/A' : transaction.costPerUnit.toFixed(2),
          costPerUnitDifferenceInPercent,
          costPerUnitDifferenceInPercentDisplay:
            transaction.costPerUnitDifferenceInPercent === null ? 'N/A' : costPerUnitDifferenceInPercentDisplay,
        };
      }),
    }));
    return applySortFilter(upgradedList, getComparator(order, orderBy), filterName, (item) => getFilteredItem(item));
    // eslint-disable-next-line
  }, [list, filterName, order, orderBy]);

  useEffect(() => {
    setFilterLoading(false);
  }, [computedFilteredList, order, orderBy]);

  const isItemNotFound = computedFilteredList.length === 0;

  const seeCost = useAccess('tobaccoInventory.views.cost');

  return (
    <Card>
      <TableToolbar filterName={filterName} onFilterName={handleFilterByName} submitOnEnter />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              isLoading={loading || filterLoading}
              size={SIZE}
              order={order}
              orderBy={orderBy}
              headLabel={getTableHead(t)}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {computedFilteredList?.map((row) => (
                <ConveyorTransactionsRow
                  key={`${row.tobaccoContainer}-${row.indx}`}
                  row={row}
                  loading={loading || filterLoading}
                  columnCount={getTableHead(t).length}
                  handleRequestSort={handleRequestSort}
                  seeCost={seeCost}
                  handleOpenCorrectionModal={handleOpenCorrectionModal}
                  handleOpenCorrectionHistoryInfo={handleOpenCorrectionHistoryInfo}
                  corrections={corrections}
                />
              ))}
            </TableBody>

            {isItemNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                    <SearchNotFound isLoading={loading} searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

ConveyorTobaccoTransactionsTable.propTypes = {
  list: PropTypes.array,
  corrections: PropTypes.array,
  handleOpenCorrectionModal: PropTypes.func,
  handleOpenCorrectionHistoryInfo: PropTypes.func,
  loading: PropTypes.bool,
};
