import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import expensesApi from '../../api/expensesApi';
import { SetAlertAndClose } from './AlertReducer';
import { LoadingStatusEnum } from '../../constants/enums';

// ----------------------------------------------------------------------------

// THUNKS
export const CreateExpense = createAsyncThunk('employees/createExpense', async (data, thunkAPI) => {
  const res = await expensesApi.create(data);

  if (res.status === 200) {
    thunkAPI.dispatch(SetAlertAndClose({ data: res.data }));
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});

export const CreateExpensesType = createAsyncThunk('employees/createExpensesType', async (type, thunkAPI) => {
  const res = await expensesApi.createType({ type });

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetExpenses = createAsyncThunk('expenses/getExpenses', async (params, thunkAPI) => {
  params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
  params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');
  const res = await expensesApi.get(params);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

// state
const initialState = {
  list: [],
  rowsCount: 0,
  balances: [],
  expensesTypes: [],
  loading: 'idle',
};

// slice
export const expensesSlice = createSlice({
  name: 'expenses',
  initialState,
  reducers: { ResetExpensesState: () => initialState },
  extraReducers: (builder) => {
    // ------------------CreateExpense-------------------------
    builder.addCase(CreateExpense.fulfilled, (state, action) => {
      const newExpense = action.payload.data;

      return {
        ...state,
        list: [...state.list, action.payload.data],
        balances: state.balances.map((el) => {
          if (el._id === newExpense.balance._id) return newExpense.balance;

          return el;
        }),
        loading: 'succeeded',
      };
    });

    builder.addCase(CreateExpense.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------CreateExpensesType-------------------------
    builder.addCase(CreateExpensesType.fulfilled, (state, action) => ({
      ...state,
      expensesTypes: [...state.expensesTypes, action.payload.data],
      loading: 'succeeded',
    }));

    builder.addCase(CreateExpensesType.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------GetExpenses-------------------------
    builder.addCase(GetExpenses.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetExpenses.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.data,
      loading: 'succeeded',
    }));

    builder.addCase(GetExpenses.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
  },
});
export const selectExpensesLoading = (state) => state.expenses.loading;
export const selectExpensesIsLoading = (state) => state.expenses.loading === LoadingStatusEnum.Pending;
export const selectExpensesList = (state) => state.expenses.list;
export const selectExpensesRowsCount = (state) => state.expenses.rowsCount;
export const selectExpensesBalances = (state) => state.expenses.balances;
export const selectExpensesTypes = (state) => state.expenses.expensesTypes;

export const { ResetExpensesState } = expensesSlice.actions;

export default expensesSlice.reducer;
