import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types'; // Import PropTypes
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import selectors from '../../../redux/reducers/dashboard/selectors';
import { GetTotalStats } from '../../../redux/reducers/dashboard/thunks';
import getLocalizedText from '../../../utils/getLocalizedText';
import AppWidgetSummary from './AppWidgetSummary';

const TotalStatsDisplay = ({ years }) => {
  const SUMMARY_WIDGET_COLORS = ['primary', 'secondary', 'info', 'warning'];
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  useEffect(() => {
    years.forEach((year) => dispatch(GetTotalStats({ year })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const totalStats = useSelector(selectors.totalStats.data);
  const totalStatsIsLoading = useSelector(selectors.totalStats.isLoading);

  const sortedTotalStats = totalStats?.length 
    ? [...totalStats].sort((a, b) => b[0]?.year - a[0]?.year) 
    : [];

  return (
    <>
      {totalStatsIsLoading && (
        <Box py={5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress disableShrink />
        </Box>
      )}
      <>
        {sortedTotalStats?.length > 0 &&
          sortedTotalStats.map((item) =>
            item.map(({ title, titleEs, items, year }) => {
              const sectionTitle = getLocalizedText({ language: i18n.language, en: title, es: titleEs });

              return (
                <Fragment key={`${title}-${year}`}>
                  <Grid item xs={12}>
                    <Typography variant="h4" mb={-1}>
                      {sectionTitle} {year}
                    </Typography>
                  </Grid>

                  <Grid container item spacing={2} xs={12}>
                    {items?.map(({ property, propertyEs, value, icon }, idx) => {
                      const color = _.nth(SUMMARY_WIDGET_COLORS, idx % SUMMARY_WIDGET_COLORS.length);

                      const localizedTitle = getLocalizedText({
                        language: i18n.language,
                        en: property,
                        es: propertyEs,
                      });

                      return (
                        <Grid item xs={6} md={3} xl={1.5} key={property}>
                          <AppWidgetSummary total={value} title={localizedTitle} icon={icon} color={color} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Fragment>
              );
            })
          )}
      </>
    </>
  );
};

TotalStatsDisplay.propTypes = {
  years: PropTypes.array.isRequired, // Validate that year is a required number
};

export default TotalStatsDisplay;
