export const GetConveyorTransactionsReducer = {
  pending: (state) => ({
    ...state,
    data: {
      list: [],
      loading: 'pending',
    },
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      data: {
        list: data.results,
        loading: 'succeeded',
      },
      corrections: data.corrections,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    data: {
      ...state.data,
      loading: 'failed',
    },
    loading: 'failed',
  }),
};

export const ClearListReducer = (state) => ({
  ...state,
  data: {
    ...state.data,
    list: [],
  },
  loading: 'idle',
});

export const GetConfigsReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      configs: data,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};
