const initialState = {
  list: [],
  loading: 'idle',
  listUpdatingCount: 0,

  selectedItem: {
    data: null,
    loading: 'idle',
  },
};

export default initialState;
