import { LoadingStatusEnum } from '../../../constants/enums';

// ----------------------------------------------------------------

export default {
  list: (state) => state.factoryOptions.list,
  listUpdatingCount: (state) => state.factoryOptions.listUpdatingCount,
  loading: (state) => state.factoryOptions.loading,
  isLoading: (state) => state.factoryOptions.loading === LoadingStatusEnum.Pending,

  selectedItem: {
    data: (state) => state.factoryOptions.selectedItem.data,
    loading: (state) => state.factoryOptions.selectedItem.loading,
    isLoading: (state) => state.factoryOptions.selectedItem.loading === LoadingStatusEnum.Pending,
  },
};
