import { Stack, Typography, TableBody, TableRow, Table } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';
import StyledModal from '../components/StyledModal';
import { ConveyorTransactionTypeEnum, DaysOfTheWeek, Weekday } from '../constants/enums';
import { useFactoryConfigValue } from '../providers/FactoryOptionsProvider';
import selectors from '../redux/reducers/conveyorTransactions/selectors';
import {
  GetCigarConveyorTransactions,
  GetConfigs,
  GetConveyorTransactions,
} from '../redux/reducers/conveyorTransactions/thunks';
import { GetCorrectionReasons } from '../redux/reducers/tobaccoProductionCorrection/thunks';
import conveyorsMenu from '../redux/reducers/conveyorsMenu/selectors';
import { ClearList } from '../redux/reducers/conveyorTransactions/actions';
import ConveyorTobaccoTransactionsTable from '../sections/@dashboard/conveyorTransactions/ConveyorTobaccoTransactionsTable';
import ConveyorStepRecordTransactionsTable from '../sections/@dashboard/conveyorTransactions/ConveyorStepRecordTransactionsTable';
import ConveyorTransactionsCorrectionForm from '../sections/@dashboard/conveyorTransactions/ConveyorTransactionsCorrectionForm';
import TobaccoProductionDefualtFilters from '../sections/@dashboard/tobaccoProductions/TobaccoProductionDefualtFilters';
import TobaccoCorrectionHistoryModal from '../sections/@dashboard/tobaccoProductions/tobaccoCorrectionLogModals/TobaccoCorrectionHistoryModal';
import { TableCell } from '../sections/@dashboard/table';
import SearchNotFound from '../components/SearchNotFound';

// --------------------------------------------------------------------------

const PAGE = 'conveyorTransactions';

export default function ConveyorTransactionsPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentLanguage = useTranslation().i18n.language;

  const params = useParams();
  const { id } = params;
  const TRANSACTION_PAGE = `${PAGE}_${id}`;

  // state
  const loading = useSelector(selectors.isLoading);
  const list = useSelector(selectors.list);
  const listIsLoading = useSelector(selectors.listIsLoading);
  const corrections = useSelector(selectors.corrections);
  const conveyorsMenuList = useSelector(conveyorsMenu.list);

  const isLoading = loading || listIsLoading;

  const [correctionModalIsOpen, setCorrectionModalIsOpen] = useState(false);
  const [correctionModalInfo, setCorrectionModalInfo] = useState(null);

  const [correctionHistoryModalIsOpen, setCorrectionHistoryModalIsOpen] = useState(false);
  const [correctionHistoryInfo, setCorrectionHistoryInfo] = useState();

  const [currentFilter, setCurrentFilter] = useState();

  const handleOpenCorrectionHistoryInfo = (data) => {
    setCorrectionHistoryInfo(data);
    setCorrectionHistoryModalIsOpen(true);
  };

  const handleCloseCorrectionModal = () => {
    setCorrectionModalIsOpen(false);
  };

  const handleOpenCorrectionModal = (data) => {
    setCorrectionModalIsOpen(true);
    dispatch(GetCorrectionReasons());
    setCorrectionModalInfo(data);
  };

  const findSubmenuById = (list, targetId) => {
    const flattenList = (items) =>
      items.reduce((acc, item) => {
        acc.push(item);
        if (item.submenu) {
          acc.push(...flattenList(item.submenu));
        }
        return acc;
      }, []);

    const flatList = flattenList(list);
    const foundItem = flatList.find((item) => item.id === targetId);

    return foundItem || null;
  };
  const menu = findSubmenuById(conveyorsMenuList, id);

  const header = menu && currentLanguage === 'en' ? menu?.title : menu?.titleEs;

  // week start
  const AttendanceFirstWeekDay = useFactoryConfigValue('AttendanceFirstWeekDay');
  const weekStartsOn = Weekday[AttendanceFirstWeekDay] || Weekday[DaysOfTheWeek.Monday];

  useEffect(() => {
    dispatch(ClearList());
  }, [dispatch, loading]);

  const onSubmit = useCallback(
    (data) => {
      const payload = {
        ...data,
        id,
      };

      if (menu?.conveyorTransactionType === ConveyorTransactionTypeEnum.TobaccoTransaction) {
        dispatch(GetConveyorTransactions(payload));
      }

      if (menu?.conveyorTransactionType === ConveyorTransactionTypeEnum.ConveyorStepRecord) {
        dispatch(GetCigarConveyorTransactions(payload));
      }
    },

    // eslint-disable-next-line
    [dispatch, id, menu]
  );

  useEffect(() => {
    dispatch(GetConfigs(id));
  }, [dispatch, id]);

  return (
    <Page title={t(`pages.${PAGE}`)}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
        <Typography variant="h4">{header}</Typography>
      </Stack>
      <TobaccoProductionDefualtFilters
        getData={setCurrentFilter}
        onSubmit={onSubmit}
        weekStartsOn={weekStartsOn}
        page={TRANSACTION_PAGE}
      />
      {menu?.conveyorTransactionType === ConveyorTransactionTypeEnum.TobaccoTransaction && (
        <ConveyorTobaccoTransactionsTable
          handleOpenCorrectionHistoryInfo={handleOpenCorrectionHistoryInfo}
          handleOpenCorrectionModal={handleOpenCorrectionModal}
          corrections={corrections}
          list={list}
          loading={isLoading}
        />
      )}
      {menu?.conveyorTransactionType === ConveyorTransactionTypeEnum.ConveyorStepRecord && (
        <ConveyorStepRecordTransactionsTable list={list} loading={isLoading} />
      )}
      {!Object.values(ConveyorTransactionTypeEnum).includes(
        menu?.conveyorTransactionType
      ) && (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="center" sx={{ py: 3 }}>
                <SearchNotFound isLoading={loading} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      <StyledModal
        maxWidth={'md'}
        title={t(`tobaccoProductionSummaryPage.correctionPopup.title`)}
        open={correctionModalIsOpen}
        handleClose={() => setCorrectionModalIsOpen(false)}
      >
        {correctionModalInfo && (
          <ConveyorTransactionsCorrectionForm
            handleCloseCorrectionModal={handleCloseCorrectionModal}
            correctionModalInfo={correctionModalInfo}
            page={PAGE}
            refreshData={() => onSubmit({ ...currentFilter, id })}
          />
        )}
      </StyledModal>
      <TobaccoCorrectionHistoryModal
        modalIsOpen={correctionHistoryModalIsOpen}
        corrections={correctionHistoryInfo}
        handleCloseModal={() => setCorrectionHistoryModalIsOpen(false)}
      />
    </Page>
  );
}
